import React, { useState } from "react";

import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const AccordionItem = ({ title, content, isOpen, onClick }) => {
  //   const [isShow, setIsShow] = useState(false);

  return (
    <div className="flex flex-col rounded-md shadow-md bg-white mb-4">
      <div
        // onClick={() => setIsShow(!isShow)}
        onClick={onClick}
        className="flex p-4 cursor-pointer"
      >
        <p className="flex-grow font-semibold">{title}</p>
        {!isOpen ? (
          <AiOutlinePlus className="text-xl" />
        ) : (
          <AiOutlineMinus className="text-xl" />
        )}
      </div>
      {isOpen && <div className="p-4 border-t">{content}</div>}
    </div>
  );
};

export default AccordionItem;
